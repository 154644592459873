import React, { useState, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'typeface-roboto';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Header from './layout/Header';
import Footer from './layout/Footer';
/*
import {
    createMuiTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
*/
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { purple } from '@material-ui/core/colors';
import { Container } from '@material-ui/core';
import Home from './routes/Home';
import Explore from './routes/Explore';
import Bibl from './routes/Bibl';
import Category from './routes/Category';
import Person from './routes/Person';
import Archive from './routes/Archive';
import Biography from './routes/Biography';
import About from './routes/About';
import NotFound from './routes/NotFound';

import LanguageContext from './utils/LanguageContext.js';
import languageLookup from './utils/languages.js';

import { useCookies } from 'react-cookie';
/** use the Google Analytics 4 (Advanced Analytics) */
import ReactGA from "react-ga4";
/** custom stylesheet */
import './App.css';

/** removed tag manager config and initialisation, using normal analytics instead
    ESM
const tagManagerArgs = { 
    gtmId: 'G-VLLBH52SZ9',
    anonymize_ip: true,
    dataLayer: {
        dataLayerName: "hobsbawm-project",
    } 
};
*/

/* ga-4 initialisation, note that ip anomymization is automatic on ga-4 */
ReactGA.initialize("G-VLLBH52SZ9");
ReactGA.send("pageview");

const theme = createTheme({
    palette: {
        primary: {
            main: '#37474f', //'#455a64',
        },
        secondary: {
            main: '#bf360c',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',

        marginBottom: '150px',
    },
    app: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    grow: {
        flexGrow: 100,
    },
}));



function App() {
    const classes = useStyles();
    const [cookies, setCookie, removeCookie] = useCookies(['language']);
    const [language, setLanguage] = useState(cookies['language'] || 'EN');

    const languageSetter = (lang) => {
        setLanguage(lang);
        setCookie('language', lang);
    };

    return (
        <Router basename="/exist/apps/hobsbawm-app">
        <ThemeProvider theme={theme}>
            <LanguageContext.Provider
                value={{
                    _: languageLookup(language),
                    setLanguage: languageSetter,
                    currentLanguage: language,
                }}
            >
                <CssBaseline />
                <div className={classes.app}>
                    <Header />

                    <Container fixed className={classes.container}>
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route path="/search">
                                <Home />
                            </Route>
                            <Route path="/explore">
                                <Explore />
                            </Route>
                            <Route path="/biography">
                                <Biography />
                            </Route>
                            <Route path="/about">
                                <About />
                            </Route>
                            <Route path="/category/:catid">
                                <Category />
                            </Route>
                            <Route path="/bibl/:id">
                                <Bibl />
                            </Route>
                            <Route path="/broadcast/:id">
                                <Bibl variant="broadcast" />
                            </Route>
                            <Route path="/person/:personid">
                                <Person />
                            </Route>
                            <Route path="/archive/:archiveid">
                                <Archive />
                            </Route>
                            <Route path="" component={NotFound}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </Container>
                    <div className={classes.grow} />
                    <Footer />
                </div>
            </LanguageContext.Provider>
        </ThemeProvider>
        </Router>
    );
}

/**
TagManager.initialize(tagManagerArgs);
 */

export default App;
