import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import parse, { domToReact } from 'html-react-parser';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import hash from 'hash-it';

import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Icon from '@mdi/react';
import { mdiPackageVariant } from '@mdi/js';

import LanguageContext from '../utils/LanguageContext.js';

// Redux
import { connect, useDispatch } from 'react-redux';
import {
    setApiQueryState,
    setApiQueryStateAndRouteAndFetch,
} from '../actions/apiQueryActions';
import { fetchDataIfNeeded } from '../actions/dataActions.js';

import FocusableHeader from '../focusableHeader.jsx';

const icons = {
    archive: (
        <Icon path={mdiPackageVariant} size={1} color="hsl(320, 70%, 30%)" />
    ),
};

const useStyles = makeStyles((theme) => ({
    monogr_of_article: {
        marginTop: theme.spacing(1),
    },
    named_container_paper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    paper_label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.875rem',
        fontWeight: 500,
        paddingBottom: theme.spacing(2),
    },

    item_type_label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'fontSize': '0.875rem',
        'fontWeight': 500,
        'paddingBottom': theme.spacing(2),
        'marginBottom': theme.spacing(3),
        '& svg': {
            position: 'relative',
            top: '6px',
            marginRight: '8px',
        },
    },

    link: {
        'color': theme.palette.secondary.main,
        'textDecoration': 'none',
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },

    title_value_title: {
        minWidth: '100px',
        display: 'inline-block',
        marginRight: '20px',
    },
    paragraph: {
        marginBottom: theme.spacing(2),
    },
    breadcrumbs: {
        marginTop: theme.spacing(2),
        '& ol': {
            alignItems: 'flex-end',
        },
        '& svg': {
            position: 'relative',
            top: '3px',
            marginRight: '8px',
        },
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Item = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    //console.log('-->', props)

    return (
        <>
            <Typography className={classes.item_type_label}>
                {icons[props.type]} {_(props.type)}{' '}
                {props.subtype ? '(' + _(props.subtype) + ')' : ''}
            </Typography>
            {domToReact(props.children, options)}
        </>
    );
};

const DateLine = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    return (
        <Typography variant="h6" component="p">
            {props.when}
        </Typography>
    );
};

const ItemTitle = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);

    let title;
    switch (props.type) {
        case 'journal-article':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;
        case 'newspaper-article':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;
        case 'book-chapter':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;
        case 'book':
            title = <i>{domToReact(props.children, options)}</i>;
            break;
        case 'archive':
            title = domToReact(props.children, options);
            break;
        default:
            title = <i>{domToReact(props.children, options)}</i>;
            break;
    }
    return (
        <Typography variant="h4" component="div">
            <FocusableHeader autoFocus>{title}</FocusableHeader>
        </Typography>
    );
};

const ArchiveBreadcrumbs = (props) => {
    const classes = useStyles();
    const breadcrumbs = props.children.filter(
        (item) => item.name === 'react:archive-breadcrumb',
    );
    return breadcrumbs.length > 0 ? (
        <Breadcrumbs separator="→" className={classes.breadcrumbs}>
            {breadcrumbs.map((item, index) => {
                //console.log('HERE', item.attribs['data-href']);
                return item.attribs['data-href'] ? (
                    <RouterLink
                        key={index}
                        to={item.attribs['data-href']}
                        className={classes.link}
                    >
                        <Icon
                            path={mdiPackageVariant}
                            size={0.8}
                            color="#555"
                        />{' '}
                        {domToReact(item.children, options)}
                    </RouterLink>
                ) : (
                    <Typography color="primary" key={index}>
                        <Icon
                            path={mdiPackageVariant}
                            size={0.8}
                            color="#777"
                        />{' '}
                        {domToReact(item.children, options)}
                    </Typography>
                );
            })}
        </Breadcrumbs>
    ) : (
        ''
    );
};

const NamedContainer = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    return (
        <Box mt={1}>
            <Paper elevation={3} className={classes.named_container_paper}>
                <Typography className={classes.paper_label}>
                    {_('named-container-type-' + props.type)}
                </Typography>
                {domToReact(props.children, options)}
            </Paper>
        </Box>
    );
};

const MonogrTitle = (props) => {
    return (
        <Box mb={3}>
            <Typography variant="h5" component="h1">
                {domToReact(props.children, options)}
            </Typography>
        </Box>
    );
};

const PageGrid = (props) => {
    const classes = useStyles();
    return (
        <Grid container spacing={10} className={classes.monogr_of_article}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridLeft = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={9}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridRight = (props) => {
    //console.log('GR', props)
    return (
        <Grid item xs={3}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridRightList = (props) => {
    //console.log('GRL', props.children)
    const { _ } = useContext(LanguageContext);
    return (
        <List
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {_('side-container-header-' + props.header)}
                </ListSubheader>
            }
        >
            {domToReact(props.children, options)}
        </List>
    );
};

const GridRightListItem = (props) => {
    //console.log('GRLI', props)
    const { _ } = useContext(LanguageContext);
    return props.link ? (
        <ListItemLink href={props.link}>
            <ListItemText
                primary={
                    props.translation
                        ? _(props.translation + '-' + props.value)
                        : props.value
                }
            />
        </ListItemLink>
    ) : (
        <ListItemText
            primary={
                props.translation
                    ? _(props.translation + '-' + props.value)
                    : props.value
            }
        />
    );
};

const TitleValue = (props) => {
    //console.log('TVprops', props);
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    // IMPROVE THIS-- use a grid...?
    if (props.translatevalue) {
        return (
            <>
                <Typography variant="body2">
                    <b className={classes.title_value_title}>
                        {_('tv-title-' + props.title)}
                    </b>
                    {_(props.translatekey + props.translatevalue)}
                </Typography>
            </>
        );
    } else {
        return (
            <>
                <Typography variant="body2">
                    <b className={classes.title_value_title}>
                        {_('tv-title-' + props.title)}
                    </b>
                    {domToReact(props.children, options)}
                </Typography>
            </>
        );
    }
};

const BiblScope = (props) => {
    return <Box mt={3}>{domToReact(props.children, options)}</Box>;
};

const Paragraph = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="body2" className={classes.paragraph}>
            {domToReact(props.children, options)}
        </Typography>
    );
};

const Link = (props) => {
    const classes = useStyles();
    return (
        <RouterLink to={props.href} className={classes.link}>
            {domToReact(props.children, options)}
        </RouterLink>
    );
};

const IndentedList = (props) => {
    const classes = useStyles();
    return (
        <Grid container direction="column">
            {domToReact(props.children, options)}
        </Grid>
    );
};

const IndentedListItem = (props) => {
    const classes = useStyles();
    //console.log(props);
    const fontVariant = (() => {
        if (props.indent === '1') {
            return 'subtitle1';
        } else if (props.indent === '2') {
            return 'subtitle2';
        } else {
            return 'caption';
        }
    })();
    return (
        <Grid
            item
            style={{
                marginLeft: (props.indent - 1).toString() + 'rem',
                marginTop: (1 / props.indent).toString() + 'rem',
            }}
        >
            <Typography variant={fontVariant}>
                {domToReact(props.children, options)}
            </Typography>
        </Grid>
    );
};

const lookups = {
    'grid': PageGrid,
    'grid-left': GridLeft,
    'grid-right': GridRight,
    'grid-right-list': GridRightList,
    'grid-right-list-item': GridRightListItem,
    'item': Item,
    'item-date': DateLine,
    'item-title': ItemTitle,
    'archive-breadcrumbs': ArchiveBreadcrumbs,
    //'monogr': Monogr,
    //'monogr-title': MonogrTitle,
    'key-value': TitleValue,
    //'bibl-scope': BiblScope,
    'named-container': NamedContainer,
    'p': Paragraph,
    'link': Link,
    'indented-list': IndentedList,
    'indented-list-item': IndentedListItem,
};

const options = {
    replace: (domNode) => {
        if (domNode.name && domNode.name.startsWith('react:')) {
            const attribs = Object.fromEntries(
                Object.entries(domNode.attribs).map(([k, v]) => {
                    return [k.replace('data-', ''), v];
                }),
            );
            const elem_name = domNode.name.split(':')[1];
            return React.createElement(
                lookups[elem_name],
                attribs,
                domNode.children,
            );
        }
    },
};

const html = `<react:item data-type="journal-article">
    <react:item-date data-when="1969">1969</react:item-date>
    <react:item-title data-type="journal-article">Problems of a Communist History</react:item-title>
    
    <react:item-authors data-href="/author/hobsbawm">
        <react:author data-href="/author/hobswbawm">Eric Hobsbawm</react:author>
        <react:author data-href="/author/another">A. N. Other</react:author>
    </react:item-authors>
    <react:grid>
        <react:grid-left>
            <react:named-container data-type="journal-article">
                <react:monogr-title>New Left Review</react:monogr-title>
                    <react:key-value data-title="language">EN</react:key-value>
                    <react:key-value data-title="published">London</react:key-value> 
                    <react:key-value data-title="publisher">Weidenfeld &amp; Nicolson</react:key-value>
                    <react:key-value data-title="date">1969</react:key-value>
                    

                    <react:bibl-scope>
                        <react:key-value data-title="volume">54</react:key-value>
                        <react:key-value data-title="pages">11–24</react:key-value>
                    </react:bibl-scope>
            </react:named-container>

            <react:named-container data-type="notes">
                <react:p>Reprinted in <react:link data-href="/item/revolutionaries">Revolutionaries: Contemporary Essays</react:link> Chapter 1.</react:p>
            </react:named-container>

            <react:named-container data-type="translations">
                <react:key-value data-title="spanish"><react:link data-href="/item/problems-historia-comunista">Problemas de una historia comunista</react:link></react:key-value>
                <react:key-value data-title="french"><react:link data-href="/item/problemes-histoire-communiste">Problèmes d'une histoire communiste</react:link></react:key-value>
            </react:named-container>
        </react:grid-left>

        <react:grid-right>
            <react:grid-right-list data-header="categories">
                <react:grid-right-list-item data-translation="category" data-value="communism" link="/category/communism">ja</react:grid-right-list-item>
                <react:grid-right-list-item data-translation="category" data-value="other" link="/category/other">je</react:grid-right-list-item>
              
            </react:grid-right-list>

            <react:grid-right-list data-header="links">
                <react:grid-right-list-item data-value="WorldCat Entry" link="http://www.worldcat.org/oclc/137349775"/>
            </react-grid-right-list>
        </react:grid-right>
    
    
    </react:grid>
       

</react:item>`;

const Archive = (props) => {
    //console.log(props.apiQueryState);
    const dispatch = useDispatch();
    const { archiveid } = useParams();
    //alert(archiveid);
    const itemData =
        props.allItemData[
            hash({
                type: 'archive',
                identifier: archiveid,
            })
        ] ?? null;

    useEffect(() => {
        dispatch(
            fetchDataIfNeeded({
                type: 'archive',
                identifier: archiveid,
            }),
        );
    }, [itemData]);

    return (
        <>
            <Helmet>
                <title>Archive</title>
            </Helmet>

            {itemData && itemData.isFetching ? (
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={9}>
                        <Box mt={5}>
                            <LinearProgress />
                        </Box>
                    </Grid>
                </Grid>
            ) : itemData && itemData.data ? (
                <Box mt={4}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={10}>
                            {parse(itemData.data, options)}
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
    query: state.router.location.query,
    allItemData: state.itemData,
    apiQueryState: state.apiQueryState,
});

export default connect(mapStateToProps)(Archive);
