import React, { useContext, useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Material UI imports
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

/*
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
*/

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import parse, { domToReact } from 'html-react-parser';

// Language Context, language translator function _
import LanguageContext from '../utils/LanguageContext.js';
import _ from '../utils/languages';

// Icons, styles
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '@mdi/react';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    mdiBookOpenVariant,
    mdiBookOpenPageVariant,
    mdiNewspaperVariantOutline,
    mdiFile,
    mdiAccount,
    mdiShape,
    mdiPackageVariant,
    mdiNoteOutline,
    mdiFileFind,
    mdiMicrophone,
    mdiAccountMultipleOutline,
    mdiTeach,
    mdiTelevisionClassic,
    mdiCloseCircle,
    mdiCheckboxMarked,
    mdiCheckboxBlankOutline,
    mdiCheckBoxOutline,

} from '@mdi/js';

import { makeStyles } from '@material-ui/core/styles';

// Redux
import { connect, useDispatch } from 'react-redux';
import hash from 'hash-it';
import {
    setApiQueryState,
    setApiQueryStateAndRoute,
    setApiQueryStateAndRouteAndFetch,
} from '../actions/apiQueryActions';
import { fetchDataIfNeeded } from '../actions/dataActions';
import { setSearchBoxAction } from '../actions/searchBoxActions';

import hlogo from '../assets/hlogo.jpg';

import qs from 'qs';

import FocusableHeader from '../focusableHeader.jsx';
import App from '../App.js';

const camelToKebab = (string) => {
    return string
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase();
};

const kebabToCamel = (string) => {
    return string
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};



const useStyles = makeStyles((theme) => ({
    paddedPaper: {
        padding: theme.spacing(3),
    },

    search: {
        position: 'relative',
        //border: 'thin solid gray',

        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {},
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),

        width: '100%',

        fontSize: '1.5rem',
    },
    resultsPaper: {
        padding: theme.spacing(5, 5, 4),
        marginBottom: theme.spacing(5),
    },
    resultItemPaper: {
        cursor: 'pointer',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    link: {
        'color': theme.palette.secondary.main,
        'textDecoration': 'none',
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },

    searchResultLabel: {
        paddingBottom: theme.spacing(3),
    },
    itemTypeLabel: {
        fontSize: '0.62rem',
        textTransform: 'uppercase',
        color: '#444',
        lineHeight: '1.4',
    },
    expansionPanel: {
        //backgroundColor: theme.palette.primary.light,
        //color: theme.palette.primary.contrastText,
        'boxShadow': theme.shadows[8],
        'backgroundColor': theme.palette.primary.main,
        'color': theme.palette.primary.contrastText,
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.contrastText,
        },
        '&:hover': {
            boxShadow: theme.shadows[12],
        },
    },
    expansionPanelHeading: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightRegular,
    },

    expansionPanelDetails: {
        padding: theme.spacing(0, 5, 3, 5),
    },

    filterResultPaper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}));


const icons = {
    'book': (
        <Icon path={mdiBookOpenVariant} size={1} color="rgb(130, 23, 23)"/>
    ),
    'book-chapter': (
        <Icon path={mdiBookOpenPageVariant} size={1} color="rgb(130, 80, 23)"/>
    ),
    'journal-article': (
        <Icon path={mdiFile} size={1} color="rgb(123, 130, 23)"/>
    ),
    'newspaper-article': (
        <Icon path={mdiNewspaperVariantOutline} size={1} color="rgb(66, 130, 23)"/>
    ),
    'review': (
        <Icon path={mdiFileFind} size={1} color="rgb(23, 130, 37)"/>
    ),
    'pamphlet': (
        <Icon path={mdiNoteOutline} size={1} color="rgb(23, 130, 94)"/>
    ),
    'interview-with': (
        <Icon path={mdiAccountMultipleOutline} size={1} color="rgb(23, 109, 130)"/>
    ),
    'lecture': (
        <Icon path={mdiTeach} size={1} color="rgb(23, 52, 130)"/>
    ),
    'feature': (
        <Icon path={mdiTelevisionClassic} size={1} color="rgb(52, 23, 130)"/>
    ),
    'talks': (
        <Icon path={mdiMicrophone} size={1} color="rgb(23, 44, 130)"/>
    ),
    'archive': (
        <Icon path={mdiPackageVariant} size={1} color="rgb(130, 23, 94)"/>
    ),
    'person': (
        <Icon path={mdiAccount} size={1} color="rgb(191, 54, 12)"/>
    ),
    'category': (
        <Icon path={mdiShape} size={1} color="rgb(191, 54, 12)"/>
    ),
};


const selectedIcons = {
    'book': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'book-chapter': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'journal-article': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'newspaper-article': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'review': (<Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'pamphlet': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'interview-with': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'lecture': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'feature': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'talks': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'archive': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'person': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/>
    ),
    'category': (
        <Icon path={mdiCheckBoxOutline} size={1.5}/> 
    ),
};

const deselectedIcons = {
    'journal-article': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'book': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'newspaper-article': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'book-chapter': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'interview-with': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'lecture': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'feature': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'talks': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'person': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'category': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'archive': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'pamphlet': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
    'review': (
        <Icon path={mdiCheckboxBlankOutline} size={1.5}/>
    ),
};


const categories = [
    'autobiographic',
    'early-writings',
    'portraits-and-obituaries',
    'historical-method',
    'transition-debate',
    'capitalism-origins-developments-results',
    'peasants-agrarian-societies',
    'bandits-and-gangsters',
    'labour-history',
    'nations-and-nationalism',
    'marxism-origins-development',
    'communism',
    'revolutions-and-revolutionaries',
    'left-britain',
    'latin-america',
    'central-europe',
    'globalization',
    'jazz',
    'art-literature-cinema',
    'appraising-hobsbawm',
];

const dateRangeMarks = [
    {
        value: 1930,
        label: 1930,
    },
    ...[1950, 1960, 1970, 1980, 1990, 2000, 2010, 2019].map((y) => ({
        value: y,
        label: y,
    })),
];

const Link = (props) => {
    const classes = useStyles();
    return (
        <RouterLink to={props.href} className={classes.link}>
            {domToReact(props.children, options)}
        </RouterLink>
    );
};

const SearchResultGroup = (props) => {
    return <>{domToReact(props.children, options)}</>;
};

const SearchResult = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    return (
        <Paper elevation={5} className={classes.resultsPaper}>
            <div className={classes.searchResultLabel}>
                <Typography variant="caption">{_(props.label)}</Typography>
            </div>
            {props.children.length > 0 ? (
                <div>{domToReact(props.children, options)}</div>
            ) : (
                <div>No results found.</div>
            )}
        </Paper>
    );
};

const ResultItem = (props) => {
    const { _ } = useContext(LanguageContext);
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Paper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.resultItemPaper}
            elevation={isHovered ? 9 : 5}
        >
            <Grid container direction="row" alignItems="center" spacing={3}>
                <Grid item>
                    <div>{icons[props.type]}</div>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption">{_(props.type)}</Typography>
                </Grid>

                <Grid item xs={9}>
                    {domToReact(props.children, options)}
                </Grid>
            </Grid>
        </Paper>
    );
};

const ResultItemTitle = (props) => {
    const { _ } = useContext(LanguageContext);
    if (props.translate) {
        return <>{_(props.translate + '-' + props.value)}</>;
    } else {
        return <>{domToReact(props.children, options)}</>;
    }
};

const NoResult = (props) => {
    const { _ } = useContext(LanguageContext);
    const classes = useStyles();
    return (
        <Paper className={classes.resultItemPaper}>
            <Typography>{_('no_matches_found')}</Typography>
        </Paper>
    );
};

const lookups = {
    'result-group': SearchResultGroup,
    'result': SearchResult,
    'result-item': ResultItem,
    'result-item-title': ResultItemTitle,
    'link': Link,
    'no-result': NoResult,
};

const options = {
    replace: (domNode) => {
        if (domNode.name && domNode.name.startsWith('react:')) {
            const attribs = Object.fromEntries(
                Object.entries(domNode.attribs).map(([k, v]) => {
                    return [k.replace('data-', ''), v];
                }),
            );
            const elem_name = domNode.name.split(':')[1];
            return React.createElement(
                lookups[elem_name],
                attribs,
                domNode.children,
            );
        }
    },
};

const ITEM_TYPES = {
    book: true,
    bookChapter: true,
    journalArticle: true,
    newspaperArticle: true,
    review: true,
    pamphlet: true,
    interviewWith: true,
    lecture: true,
    feature: true,
    talks: true,
    archive: true,
};

const Home = (props) => {
    const dispatch = useDispatch();
    const { _ } = useContext(LanguageContext);
    const classes = useStyles();

    const [filterResultPaperExpanded, setFilterResultPaperExpanded] = useState(
        false,
    );

    //console.log(props.pathname);
    const [isInitialLoad, setIsInitialLoad] = useState(true);


    const [itemTypeCheckedBoxState, setItemTypeCheckedBoxState] = useState(
        ITEM_TYPES,
    );


    const handleItemTypeCheckedBoxChange = (e) => {
        setItemTypeCheckedBoxState({
            ...itemTypeCheckedBoxState,
            [e.target.name]: e.target.checked,
        });
    };

    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState(ITEM_TYPES);

    const handleSelectAllClick = (e) => {
        Object.entries(itemTypeCheckedBoxState).forEach(([key, value]) => { 
            value = true;
            console.log(key, value);
            
        });
    };
    
    // Dispatches setSearchFormAndRoute from changes to item type
    // (TO DO: get from the URL on page load...!)
    useEffect(() => {
        // First check whether any of the boxes has been deselected
        // (if all selected, we won't bother)
        const boxes_unselected = Object.entries(itemTypeCheckedBoxState).some(
            ([key, value]) => value === false,
        );
        // I think here we need to deal with boxes being all selected again,
        // and thereby removing this filter...

        if (boxes_unselected) {
            // Get the selected items (filter out false, get array of keys,
            // change to kebab case to match XML on server side)
            const selectedItemTypes = Object.entries(itemTypeCheckedBoxState)
                .filter(([key, value]) => value)
                .map(([key, value]) => camelToKebab(key));

            // Dispatch the fellow as the man said
            dispatch(
                setApiQueryStateAndRouteAndFetch({
                    ...props.apiQueryState,
                    query: { ...props.apiQueryState.query, selectedItemTypes },
                }),
            );
        } else {
            dispatch(
                setApiQueryStateAndRouteAndFetch({
                    ...props.apiQueryState,
                    query: {
                        ...props.apiQueryState.query,
                        selectedItemTypes: [],
                    },
                }),
            );
        }
    }, [itemTypeCheckedBoxState]);

    /** date range originally 1936 corrected to 1930 to suit lower bound dates */
    const [dateRangeValue, setDateRangeValue] = useState([1930, 2019]);

    const [dateRange] = useDebounce(dateRangeValue, 700);

    useEffect(() => {
        // If the date is set to anything but the whole range, don't bother
        if (dateRange[0] !== 1930 || dateRange[1] !== 2019) {
            dispatch(
                setApiQueryStateAndRouteAndFetch({
                    ...props.apiQueryState,
                    query: {
                        ...props.apiQueryState.query,
                        earliestDate: dateRange[0],
                        latestDate: dateRange[1],
                    },
                }),
            );
        } else {
            const newQueryBit = { ...props.apiQueryState.query };
            delete newQueryBit['earliestDate'];
            delete newQueryBit['latestDate'];
            dispatch(
                setApiQueryStateAndRouteAndFetch({
                    ...props.apiQueryState,
                    query: newQueryBit,
                }),
            );
        }
    }, [dateRange]);

    // Setting the initial value of search box --
    // either we grab it from the URL if it's initial load
    // or from the state.searchForm store (and only if the
    // pathname is /search ...)

    // const [searchBoxValue, setSearchBoxValue] = useState();
    const searchBoxValue = props.searchBoxValue;
    const setSearchBoxValue = (value) => dispatch(setSearchBoxAction(value));

    const [searchTerm] = useDebounce(searchBoxValue, 700);

    // The current data item to show
    const itemData = props.allItemData[hash(props.apiQueryState)] ?? null;

    //console.log('itemData', itemData);
    //console.log('ST', searchTerm);

    useEffect(() => {
        // Effect called on changes of searchTerm,
        // which is a debounced version of searchValueBox;

        // isInitialLoad is there so we can grab the URL on first mount
        // (being different to just deleting the search term)
        // Otherwise, just dispatch setSearchFormAndRoute action
        // (which is where all the logic is now)

        if (isInitialLoad) {
            // If the component has just loaded, dispatch a setSearch
            // to set state.searchForm to the query grabbed from the
            // router (parsed into an object)
            //alert('is initial load');
            if (props.pathname === '/search') {
                const searchParamsObject = qs.parse(props.search, {
                    ignoreQueryPrefix: true,
                });

                dispatch(
                    setApiQueryStateAndRouteAndFetch({
                        type: 'search',
                        identifier: '',
                        query: searchParamsObject,
                    }),
                );

                // Setting up the interface from URL on first load

                // If any filter is set, open the filter panel
                if (
                    (searchParamsObject.earliestDate &&
                        searchParamsObject.latestDate) ||
                    searchParamsObject.selectedItemTypes
                ) {
                    setFilterResultPaperExpanded(true);
                }

                // If search term is in URL, put that in the search box
                if (searchParamsObject.search) {
                    console.log('props.search', props.search);
                    setSearchBoxValue(searchParamsObject.search);
                }

                // Set the date range sliders if URL
                if (
                    searchParamsObject.earliestDate &&
                    searchParamsObject.latestDate
                ) {
                    setDateRangeValue([
                        Number(searchParamsObject.earliestDate),
                        Number(searchParamsObject.latestDate),
                    ]);
                }

                // Set the itemType checkboxes if in URL
                if (searchParamsObject.selectedItemTypes) {
                    console.log(
                        'SELECTEDITEMTYPES-->',
                        searchParamsObject.selectedItemTypes,
                    );
                    const checkedBoxes = Object.assign(
                        {},
                        itemTypeCheckedBoxState,
                    );
                    const updatedCheckedBoxes = Object.keys(ITEM_TYPES).reduce(
                        (acc, val) => {
                            console.log(acc, val);
                            acc[
                                val
                            ] = searchParamsObject.selectedItemTypes.includes(
                                camelToKebab(val),
                            );

                            return acc;
                        },
                        {},
                    );
                    console.log('UPDATED CHECKED BOXES', updatedCheckedBoxes);
                    setItemTypeCheckedBoxState(updatedCheckedBoxes);
                }
            }
            //dispatch(fetchDataIfNeeded(`/search${props.search}`));
            setIsInitialLoad(false);
        } else {
            //console.log('notinitialloadtrigger');
            // Otherwise, on change of searchTerm, dispatch action
            // to set state.searchForm and the router

            if (searchTerm) {
                dispatch(
                    setApiQueryStateAndRouteAndFetch({
                        //...props.apiQueryState,
                        type: 'search',
                        identifier: '',
                        query: {
                            ...props.apiQueryState.query,
                            search: searchTerm,
                        },
                    }),
                );
            } else {
                dispatch(
                    setApiQueryStateAndRouteAndFetch({
                        //...props.apiQueryState,
                        type: '',
                        identifier: '',
                        query: {},
                    }),
                );
            }
        }
    }, [searchTerm]);

    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={6}>
            <Helmet>
                <title>
                    {props.pathname === '/search'
                        ? 'Eric Hobsbawm Bibliography | Search "' + searchTerm + '"'
                        : 'Eric Hobsbawm Bibliography'}
                </title>
            </Helmet>
            <Grid item xs={12}>
                <h1></h1>
                {!searchTerm ? (
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={7}>
                            <Typography variant="h4" component="h1">
                                {_('welcome_h1')}
                            </Typography>
                            <Box mt={3}>
                                <Typography variant="body1">
                                    {' '}
                                    {parse(_('welcome_p1'), options)}{' '}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}

                <Grid container direction="row" justifyContent="center">
                    <Grid item md={9} xs={12}>
                        <Box mt={searchTerm ? 4 : 6}>
                            <Paper
                                className={classes.paddedPaper}
                                elevation={searchBoxValue ? 10 : 4}
                            >
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase autoFocus
                                        width={100}
                                        value={searchBoxValue}
                                        onChange={(e) =>
                                            setSearchBoxValue(e.target.value)
                                        }
                                        placeholder={
                                            _('search_box_label') + '…'
                                        }
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search', 'tabIndex': '-1' }}
                                    />
                                </div>
                            </Paper>
                        </Box>

                        {!searchTerm ? (
                            <Box mt={8}>
                                <Box>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        style={{ marginLeft: '1rem' }}
                                    >

                                        <Grid item md={1} xs={4} />
                                        <Grid item container md={10} xs={12}>
                                            <Grid item container direction="row" justifyContent="space-between">
                                                <Grid item container md={6} xs={12} style={{ marginBottom: 8 }}>
                                                    <Grid item container direction="column">
                                                        {categories
                                                            .slice(0, 10)
                                                            .map(
                                                                (
                                                                    cat,
                                                                    index,
                                                                ) => {
                                                                    return (
                                                                        <Grid
                                                                            item container
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <RouterLink
                                                                                to={
                                                                                    '/category/' +
                                                                                    cat
                                                                                }
                                                                                className={
                                                                                    classes.link
                                                                                }
                                                                            >
                                                                                <Typography>
                                                                                    {_(
                                                                                        'category-' +
                                                                                        cat,
                                                                                    )}
                                                                                </Typography>
                                                                            </RouterLink>
                                                                        </Grid>
                                                                    );
                                                                },
                                                            )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item container md={6} xs={12}>
                                                    <Grid item container direction="column">
                                                        {categories
                                                            .slice(10, 20)
                                                            .map(
                                                                (
                                                                    cat,
                                                                    index,
                                                                ) => {
                                                                    return (
                                                                        <Grid
                                                                            item container
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <RouterLink
                                                                                to={
                                                                                    '/category/' +
                                                                                    cat
                                                                                }
                                                                                className={
                                                                                    classes.link
                                                                                }
                                                                            >
                                                                                <Typography>
                                                                                    {_(
                                                                                        'category-' +
                                                                                        cat,
                                                                                    )}
                                                                                </Typography>
                                                                            </RouterLink>
                                                                        </Grid>
                                                                    );
                                                                },
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>

                {itemData && itemData.data ? (
                    <Grid container direction="row" justifyContent="center">
                        <Grid item container md={12} xs={12}>
                            <Box mt={5}>
                                <Accordion
                                    className={classes.expansionPanel}
                                    expanded={filterResultPaperExpanded}
                                    onChange={() =>
                                        setFilterResultPaperExpanded(
                                            !filterResultPaperExpanded,
                                        )
                                    }
                                >
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon color="primary" />
                                        }
                                    >
                                        <Typography component="div" className={classes.expansionPanelHeading}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FilterListIcon />
                                                <span
                                                    style={{
                                                        marginLeft: '1rem',
                                                    }}
                                                >
                                                    Filter search results
                                                </span>
                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        className={
                                            classes.expansionPanelDetails
                                        }
                                    >
                                        <Grid container direction="column">
                                            <FormControl component="fieldset">
                                                <Paper
                                                    elevation={6}
                                                    className={
                                                        classes.filterResultPaper
                                                    }
                                                >
                                                    <Grid item container xs={12}>
                                                        <FormLabel component="legend">
                                                            Select to Filter
                                                        </FormLabel>
                                                    </Grid>
                                                    <Box mt={3}>
                                                        <FormGroup>
                                                            <Grid item container xs={12}>
                                                                <Grid item container justifyContent="space-around" spacing={4}>
                                                                    {Object.entries(
                                                                        itemTypeCheckedBoxState,
                                                                    ).map(
                                                                        (
                                                                            [
                                                                                key,
                                                                                value,
                                                                            ],
                                                                            index,
                                                                        ) => {
                                                                            return (
                                                                                <Grid
                                                                                    item
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    xs={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <FormControlLabel
                                                                                        labelPlacement="bottom"
                                                                                        control={
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    value
                                                                                                        ? `${_(
                                                                                                            'exclude',
                                                                                                        )} ${_(
                                                                                                            camelToKebab(
                                                                                                                key,
                                                                                                            ) +
                                                                                                            '_plural',
                                                                                                        )}`
                                                                                                        : `${_(
                                                                                                            'include',
                                                                                                        )} ${_(
                                                                                                            camelToKebab(
                                                                                                                key,
                                                                                                            ) +
                                                                                                            '_plural',
                                                                                                        )}`
                                                                                                }
                                                                                            >
                                                                                                <Checkbox
                                                                                                    color='default'
                                                                                                    checked={
                                                                                                        value
                                                                                                    }
                                                                                                    name={
                                                                                                        key
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleItemTypeCheckedBoxChange
                                                                                                    }
                                                                                                    icon={
                                                                                                        deselectedIcons[
                                                                                                        camelToKebab(
                                                                                                            key,
                                                                                                        )
                                                                                                        ]
                                                                                                    }
                                                                                                    
                                                                                                    checkedIcon={
                                                                                                        selectedIcons[
                                                                                                        camelToKebab(
                                                                                                            key,
                                                                                                        )
                                                                                                        ]
                                                                                                    }
                                                                                                    
                                                                                                />
                                                                                            </Tooltip>
                                                                                        }
                                                                                        label={
                                                                                            <Typography
                                                                                                variant="caption"
                                                                                                style={{
                                                                                                    color: value
                                                                                                        ? '#333'
                                                                                                        : '#AAA',
                                                                                                }}
                                                                                            >
                                                                                                {_(
                                                                                                    camelToKebab(
                                                                                                        key,
                                                                                                    ),
                                                                                                )}
                                                                                            </Typography>
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            );
                                                                        },
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            {/*}
                                                            <Divider role="presentation"/>
                                                            <Typography component="div" align="center" display='block'>
                                                                <ButtonGroup>
                                                                    <Button onClick={handleSelectAllClick} variant="contained">{'Select all'}</Button>
                                                                </ButtonGroup>
                                                            </Typography>
                                                            */}
                                                        </FormGroup>
                                                    </Box>
                                                </Paper>
                                                <Paper
                                                    elevation={6}
                                                    className={
                                                        classes.filterResultPaper
                                                    }
                                                >
                                                    <Grid item xs={12}>
                                                        <FormLabel component="legend">
                                                            Date Range
                                                        </FormLabel>
                                                    </Grid>
                                                    <Box mt={2} mb={2}>
                                                        <FormGroup>
                                                            <Box
                                                                style={{
                                                                    padding:
                                                                        '2rem 2rem 0 1.5rem',
                                                                }}
                                                            >
                                                                <Slider
                                                                    color="secondary"
                                                                    value={
                                                                        dateRangeValue
                                                                    }
                                                                    onChange={(e, newValue,) => {
                                                                        setDateRangeValue(newValue,);
                                                                        console.log("value:--->", newValue);
                                                                    }}
                                                                    valueLabelDisplay="auto"
                                                                    step={1}
                                                                    marks={dateRangeMarks}
                                                                    min={1930}
                                                                    max={2019}
                                                                />
                                                            </Box>
                                                        </FormGroup>
                                                    </Box>
                                                </Paper>
                                            </FormControl>
                                        </Grid>
                                        <Divider />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}

                {itemData && itemData.isFetching ? (
                    <Grid container direction="row" justifyContent="center">
                        <Grid item md={9} xs={12}>
                            <Box mt={5}>
                                <LinearProgress />
                            </Box>
                            <div className={classes.grow}></div>
                        </Grid>
                    </Grid>
                ) : itemData && itemData.data ? (
                    <Grid container direction="row" justifyContent="center">
                        <Grid item md={12} xs={12}>
                            <Box mt={4}>{parse(itemData.data, options)}</Box>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
    query: state.router.location.query,
    apiQueryState: state.apiQueryState,
    allItemData: state.itemData,
    searchBoxValue: state.searchBoxValue,
});

export default connect(mapStateToProps)(Home);
