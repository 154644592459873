import { combineReducers } from 'redux';
import { SET_API_QUERY_STATE } from '../actions/apiQueryActions.js';

const modelApiQueryState = {
    type: 'search', // or 'category' or 'bibl', etc.
    identifier: '',
    query: {
        search: '',
    },
};

const apiQueryState = (state = {}, action) => {
    //console.log('apiQueryState Reducer action: ', action);
    switch (action.type) {
        case SET_API_QUERY_STATE:
            //console.log(action);
            return action.apiQueryState;
        default:
            return state;
    }
};

export default apiQueryState;
