import * as qs from 'qs';
import hash from 'hash-it';
import { API_URL } from '../config';

export const REQUEST_DATA = 'REQUEST_DATA';
export const requestSearchData = (apiQueryState) => {
    return {
        type: REQUEST_DATA,
        apiQueryState,
    };
};

export const RECEIVE_DATA = 'RECEIVE_DATA';
export const receiveData = (apiQueryState, data) => {
    //console.log('receiveData', data);
    return {
        type: RECEIVE_DATA,
        data,
        apiQueryState,
        receivedAt: Date.now(),
    };
};

const modelApiQueryState = {
    type: 'search', // or 'category' or 'bibl', etc.
    identifier: '',
    query: {
        search: '',
    },
};

const build_url_end = (apiQueryState) => {
    //console.log('api query state', apiQueryState);
    let url_end = '/';
    url_end += apiQueryState.type;
    if (apiQueryState.identifier) {
        url_end += '/' + apiQueryState.identifier;
    }
    if (apiQueryState.query) {
        url_end += qs.stringify(apiQueryState.query, {
            arrayFormat: 'brackets',
            encode: false,
            addQueryPrefix: true,
        });
    }
    return url_end;
};

export const fetchData = (apiQueryState) => {
    return (dispatch) => {
        //console.log('!!!APIQUERYSTATE', apiQueryState);

        dispatch(requestSearchData(apiQueryState));
        console.log('starting to fetch data', apiQueryState);
        const full_url = `${API_URL}${build_url_end(apiQueryState)}`;
		const eh_header = new Headers();
        //eh_header.append("Access-Control-Request-Headers", "Access-Control-Allow-Origin," +
        //    "Access-Control-Allow-Origin," +
        //    "Access-Control-Allow-Headers");
        //eh_header.append("Access-Control-Allow-Headers", "Access-Control-Allow-Origin");
 		const request = new Request(full_url, { method: 'POST', cors: 'no-cors', headers: eh_header, referrerPolicy: 'no-referrer'});
        console.log('fetchData url', full_url);
        return fetch(request)
            .then(
                (response) => response.text(),
                (error) => console.log('An error occured, ', error),
            )
            .then((data) => dispatch(receiveData(apiQueryState, data)));
    };
};

const shouldFetchData = (state, apiQueryState) => {
    const data = state.itemData[hash(apiQueryState)]; // HASHED API QUERY STATE!

    // If there's nothing in the query state, nothing to get
    if (
        Object.keys(apiQueryState).length === 0 ||
        (apiQueryState.query && Object.keys(apiQueryState.query).length === 0)
    ) {
        console.log('dataAction.js:shouldFetchData Should not get data');
        return false;

        // If query state contains nothing but empty selectedItemTypes, nothing to get
    } else if (
        Object.keys(apiQueryState).length === 1 &&
        apiQueryState.query &&
        apiQueryState.query.selectedItemTypes &&
        apiQueryState.query.selectedItemTypes.length === 0
    ) {
        return false;
        // If no data, we should get it
    } else if (!data) {
        console.log('shouldFetchData apiQueryState', apiQueryState);
        return true;
        // If we're already fetching the data, don't start again
    } else if (data.isFetching) {
        return false;
        // Otherwise, don't bother
    } else {
        return false;
    }
};

export const fetchDataIfNeeded = (apiQueryState) => {
    return (dispatch, getState) => {
        if (shouldFetchData(getState(), apiQueryState)) {
            console.log('Using fetch...');
            return dispatch(fetchData(apiQueryState));
        } else {
            console.log('dataActions.js:fetchDataIfNeeded Already have data');
            return Promise.resolve();
        }
    };
};
