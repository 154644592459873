import { SET_SEARCH_BOX_VALUE } from '../actions/searchBoxActions';

const searchBoxValue = (state = '', action) => {
    switch (action.type) {
        case SET_SEARCH_BOX_VALUE:
            return action.searchBoxValue;
        default:
            return state;
    }
}

export default searchBoxValue;