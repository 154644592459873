import React, { useContext, useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Helmet } from 'react-helmet';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LanguageContext from '../utils/LanguageContext.js';
import _ from '../utils/languages';

import Icon from '@mdi/react';
import {
    mdiBookOpenVariant,
    mdiBookOpenPageVariant,
    mdiNewspaperVariantOutline,
    mdiFile,
    mdiAccount,
    mdiShape,
    mdiPackageVariant,
    mdiNoteOutline,
    mdiFileFind,
    mdiMicrophone,
    mdiAccountMultipleOutline,
    mdiTeach,
    mdiTelevisionClassic,
} from '@mdi/js';

// Redux
import { connect, useDispatch } from 'react-redux';
import { fetchDataIfNeeded } from '../actions/dataActions';
import hash from 'hash-it';

import FocusableHeader from '../focusableHeader.jsx';

const useStyles = makeStyles((theme) => ({
    paddedPaper: {
        padding: theme.spacing(3),
    },

    search: {
        position: 'relative',
        //border: 'thin solid gray',

        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {},
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),

        width: '100%',

        fontSize: '1.5rem',
    },
    resultsPaper: {
        padding: theme.spacing(5, 5, 4),
    },
    resultItemGroupPaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    resultItemPaper: {
        cursor: 'pointer',
        padding: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    link: {
        'color': theme.palette.secondary.main,
        'textDecoration': 'none',
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },

    searchResultLabel: {
        //paddingBottom: theme.spacing(6),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(1),
    },
    item_type_label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'fontSize': '0.875rem',
        'fontWeight': 500,
        'paddingBottom': theme.spacing(2),
        'marginBottom': theme.spacing(3),
        '& svg': {
            position: 'relative',
            top: '6px',
            marginRight: '8px',
        },
    },
    title_value_title: {
        minWidth: '120px',
        maxWidth: '120px',
        display: 'inline-block',
        marginRight: '10px',
        wordBreak: 'break-word',
        outline: 'none',
    },
}));

const icons = {
    'book': (
        <Icon path={mdiBookOpenVariant} size={1} color="hsl(32, 70%, 30%)" />
    ),
    'book-chapter': (
        <Icon
            path={mdiBookOpenPageVariant}
            size={1}
            color="hsl(64, 70%, 30%)"
        />
    ),
    'journal-article': (
        <Icon path={mdiFile} size={1} color="hsl(96, 70%, 30%)" />
    ),
    'newspaper-article': (
        <Icon
            path={mdiNewspaperVariantOutline}
            size={1}
            color="hsl(128, 70%, 30%)"
        />
    ),
    'review': <Icon path={mdiFileFind} size={1} color="hsl(160, 70%, 30%)" />,
    'pamphlet': (
        <Icon path={mdiNoteOutline} size={1} color="hsl(192, 70%, 30%)" />
    ),
    'interview-with': (
        <Icon
            path={mdiAccountMultipleOutline}
            size={1}
            color="hsl(224, 70%, 30%)"
        />
    ),
    'lecture': <Icon path={mdiTeach} size={1} color="hsl(256, 70%, 30%)" />,
    'feature': (
        <Icon path={mdiTelevisionClassic} size={1} color="hsl(288, 70%, 30%)" />
    ),
    'talks': <Icon path={mdiMicrophone} size={1} color="hsl(320, 70%, 30%)" />,
    'archive': (
        <Icon path={mdiPackageVariant} size={1} color="hsl(352, 70%, 30%)" />
    ),
    'category': <Icon path={mdiShape} size={1} color="#bf360c" />,
};

const Link = (props) => {
    const classes = useStyles();
    return (
        <RouterLink to={props.href} className={classes.link}>
            {domToReact(props.children, options)}
        </RouterLink>
    );
};

const SearchResult = (props) => {
    const classes = useStyles();
    return (
        <>
            {props.children.length > 0 ? (
                <div>{domToReact(props.children, options)}</div>
            ) : (
                <div>No results found.</div>
            )}
        </>
    );
};

const ResultItemGroup = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    return (
        <Paper className={classes.resultItemGroupPaper} elevation={4}>
            <div className={classes.searchResultLabel}>
                {/*<Typography variant="subtitle1">
                <span style={{marginRight: '0.5rem', position: 'relative', top: 5}}>{icons[props.type]}</span> {_(props.type + '_plural')}
                </Typography>*/}
            </div>
            {domToReact(props.children, options)}
        </Paper>
    );
};

const ResultItem = (props) => {
    const { _ } = useContext(LanguageContext);
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    console.log(props);
    return (
        <Paper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.resultItemPaper}
            elevation={isHovered ? 9 : 4}
        >
            <Grid container direction="row" alignItems="center" spacing={3}>
                <Grid item>
                    <div>{icons[props.type]}</div>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption">{_(props.type)}</Typography>
                </Grid>

                <Grid item xs={9}>
                    {domToReact(props.children, options)}
                    {props.translationscount ? (
                        <div style={{ marginTop: '0.75rem' }}>
                            <Typography variant="caption" color="textSecondary">
                                ({props.translationscount}{' '}
                                {props.translationscount > 1
                                    ? _('translation_plural')
                                    : _('translation')}
                                )
                            </Typography>
                        </div>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

const ResultItemTitle = (props) => {
    const { _ } = useContext(LanguageContext);
    if (props.translate) {
        return <>{_(props.translate + '-' + props.value)}</>;
    } else {
        return <>{domToReact(props.children, options)}</>;
    }
};

const lookups = {
    'result': SearchResult,
    'result-item': ResultItem,
    'result-item-group': ResultItemGroup,
    'result-item-title': ResultItemTitle,
    'link': Link,
};

const options = {
    replace: (domNode) => {
        if (domNode.name && domNode.name.startsWith('react:')) {
            const attribs = Object.fromEntries(
                Object.entries(domNode.attribs).map(([k, v]) => {
                    return [k.replace('data-', ''), v];
                }),
            );
            const elem_name = domNode.name.split(':')[1];
            return React.createElement(
                lookups[elem_name],
                attribs,
                domNode.children,
            );
        }
    },
};

const Category = (props) => {
    const { _ } = useContext(LanguageContext);
    const classes = useStyles();
    const { catid } = useParams();
    const dispatch = useDispatch();

    const itemData =
        props.allItemData[
            hash({
                type: 'category',
                identifier: catid,
            })
        ] ?? null;

    useEffect(() => {
        dispatch(
            fetchDataIfNeeded({
                type: 'category',
                identifier: catid,
            }),
        );
    }, []);

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={6}
        >
            <Helmet>
                <title>{'Eric Hobsbawm Bibliography | ' + _('category-' + catid)}</title>
            </Helmet>
            <Grid item xs={10}>
                <Grid container direction="row" justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <Typography className={classes.item_type_label}>
                                {icons['category']} {_('category')}
                            </Typography>
                            <Typography variant="h4" component="div">
                                <FocusableHeader className="title_value_title">{_('category-' + catid)}{' '}</FocusableHeader>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box mt={3} mb={3}>
                        <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: _('cattext_' + catid),
                            }}
                        ></Typography>
                    </Box>
                </Grid>

                {itemData && itemData.isFetching ? (
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12}>
                            <Box mt={5}>
                                <LinearProgress />
                            </Box>
                        </Grid>
                    </Grid>
                ) : itemData && itemData.data ? (
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Box mt={4}>{parse(itemData.data, options)}</Box>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
    query: state.router.location.query,
    allItemData: state.itemData,
});

export default connect(mapStateToProps)(Category);
