import React, { useContext, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

function range(start, end) {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const YEARS = range(1940, 2010);

const useStyles = makeStyles(theme => ({
    gridContainer: {
        //height: '400px',
    },
    yearContainer: {
        //border: 'thin solid black',
        //width: '16px',
        padding: 0,
    },
    item: {
        height: '16px',
        width: '16px',
        border: 'thin solid white',
        backgroundColor: theme.palette.secondary.dark,
    },
}));

const Explore = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            alignContent="flex-end"
            className={classes.gridContainer}
        >
            {YEARS.map(year => {
                return (
                    <Grid item className={classes.yearContainer}>
                        <Grid
                            container
                            direction="column"
                            alignContent="flex-end"
                            justifyContent="flex-end"
                        >
                            {range(1, getRandomInt(2, 20)).map(n => {
                                return (
                                    <Tooltip title="Bandits: or how I learned to stop worrying and love the neck-scarf">
                                        <Grid
                                            item
                                            className={classes.item}
                                        ></Grid>
                                    </Tooltip>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Explore;

// TRY: https://www.npmjs.com/package/html-react-parser instead... looks much nicer
