

import React from 'react';
import { Link } from 'react-router-dom';
import MagNet from '../assets/magnet.png';

const NotFound = () => (
    <div>
        <h1>This page is not for turning</h1>
        <img src={MagNet} width="20%" height="20%"/>
        <p><b>MagNet reports possible socialist militancy</b></p>
        <p>Or more likely some sort of protocol/addressing issue</p>
        <link to="/home"/>
    </div>
)

export default NotFound;