import React, { useContext, Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';

import Drawer from '@material-ui/core/Drawer';

import ReactCountryFlag from 'react-country-flag';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';

import LanguageContext from '../utils/LanguageContext';

import { connect, useDispatch } from 'react-redux';
import hash from 'hash-it';
import { setApiQueryState } from '../actions/apiQueryActions.js';
import { setSearchBoxAction } from '../actions/searchBoxActions.js';

const FLAGS = {
    DEBUG: '🏴',
    EN: <Typography variant="caption"><ReactCountryFlag countryCode="gb" svg />{<span className="cflag">EN</span>}</Typography>,
    FR: <Typography variant="caption"><ReactCountryFlag countryCode="fr" svg />{<span className="cflag">FR</span>}</Typography>,
    ES: <Typography variant="caption"><ReactCountryFlag countryCode="es" svg />{<span className="cflag">ES</span>}</Typography>,
    IT: <Typography variant="caption"><ReactCountryFlag countryCode="it" svg />{<span className="cflag">IT</span>}</Typography>,
};

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        'display': 'none',
        'textDecoration': 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            color: theme.palette.secondary.contrastText,
        },
        '&:visited': {},
    },
    search: {
        'position': 'relative',
        'borderRadius': theme.shape.borderRadius,
        'backgroundColor': alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        'marginRight': theme.spacing(3),
        'marginLeft': 0,
        'width': '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(4),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    menuBarButton: {
        marginLeft: theme.spacing(6),
        //marginRight: theme.spacing(3),
    },
}));

export default function PrimarySearchAppBar() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const { _, setLanguage, currentLanguage } = useContext(LanguageContext);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const languageMenuSet = (lang) => {
        handleMenuClose();
        setLanguage(lang);
    };

    const [showMenu, setShowMenu] = useState(false);

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {
    /*<MenuItem onClick={() => languageMenuSet('DEBUG')}>DEBUG</MenuItem>*/
            }
            <MenuItem onClick={() => languageMenuSet('EN')}>
                <ReactCountryFlag countryCode="gb" svg/>{' '}
                <span style={{ marginLeft: '0.7rem' }}>English</span>
            </MenuItem>
            <MenuItem onClick={() => languageMenuSet('ES')}>
                <ReactCountryFlag countryCode="es" svg/>{' '}
                <span style={{ marginLeft: '0.7rem' }}>Español</span>
            </MenuItem>
            <MenuItem onClick={() => languageMenuSet('FR')}>
                <ReactCountryFlag countryCode="fr" svg/>{' '}
                <span style={{ marginLeft: '0.7rem' }}>Français</span>
            </MenuItem>
            {
                /*
                <MenuItem onClick={() => languageMenuSet('IT')}>
                <ReactCountryFlag countryCode="it" />{' '}
                <span style={{ marginLeft: '0.7rem' }}>Italiano</span>
                </MenuItem>
                */
                
            }
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Fragment>
            <Drawer open={showMenu} onClose={() => setShowMenu(false)}>
                <div style={{ width: 250 }}>
                    <List>
                        <ListItem
                            button
                            key="Home"
                            component={RouterLink}
                            to="/"
                            onClick={() => {
                                setShowMenu(false);
                                dispatch(setSearchBoxAction(''));
                            }}
                        >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button key="Other">
                            <ListItemText primary="Other" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>

            <div className={classes.grow}>
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography
                            component={RouterLink}
                            className={classes.title}
                            variant="h6"
                            to="/"
                            onClick={() => {
                                dispatch(setApiQueryState({}));

                                dispatch(setSearchBoxAction(''));
                            }}
                            noWrap
                        >
                            {_('site_title')}
                        </Typography>

                        <Button
                            className={classes.menuBarButton}
                            color="inherit"
                            component={RouterLink}
                            to="/"
                            onClick={() => {
                                dispatch(setApiQueryState({}));

                                dispatch(setSearchBoxAction(''));
                            }}
                        >
                            {_('home_button')}
                        </Button>

                        <Button
                            className={classes.menuBarButton}
                            color="inherit"
                            component={RouterLink}
                            to="/biography"
                        >
                            {_('biography_button')}
                        </Button>

                        <Button
                            className={classes.menuBarButton}
                            color="inherit"
                            component={RouterLink}
                            to="/about"
                        >
                            {_('about_button')}
                        </Button>
                        {/*
                        <Button
                            className={classes.menuBarButton}
                            color="inherit"
                            component={RouterLink}
                            to="/explore"
                        >
                            {_('explore_the_bibliography_button')}
                        </Button>*/}

                        <div className={classes.grow} />

                        <div className={classes.sectionDesktop}>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                {FLAGS[currentLanguage]}
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
        </Fragment>
    );
}
