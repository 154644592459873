import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, combineReducers, compose, applyMiddleware, } from 'redux';
import { ConnectedRouter, connectRouter, routerMiddleware, } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';

// Import custom reducers
import apiQueryState from './reducers/apiQueryReducers.js';
import itemData from './reducers/dataReducers.js';
import searchBoxValue from './reducers/searchBoxReducers.js';

import { IS_DEV } from './config';

if ( IS_DEV === false) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.table = () => {}
}

const history = createBrowserHistory();

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        apiQueryState,
        itemData,
        searchBoxValue,
    });

// Check the config.js file for DEV status;
// If production, turn off the redux browser devtools
const middleware = IS_DEV
    ? compose(
          applyMiddleware(routerMiddleware(history), thunkMiddleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    : compose(applyMiddleware(routerMiddleware(history), thunkMiddleware));

const store = createStore(createRootReducer(history), middleware);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
