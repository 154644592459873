import React, { useContext, useState, useEffect, useId } from 'react';
import { Helmet } from 'react-helmet';
import parse, { domToReact } from 'html-react-parser';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import hash from 'hash-it';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';

import Icon from '@mdi/react';
import {
    mdiTelevisionBox,
    mdiBookOpenPageVariantOutline,
    mdiBookOpenVariant,
    mdiBookOpenPageVariant,
    mdiNewspaperVariantOutline,
    mdiFile,
    mdiAccount,
    mdiShape,
    mdiPackageVariant,
    mdiNoteOutline,
    mdiFileFind,
    mdiMicrophone,
    mdiAccountMultipleOutline,
    mdiTeach,
    mdiTelevisionClassic,
    mdiOpenInNew,
} from '@mdi/js';

import LanguageContext from '../utils/LanguageContext.js';

// Redux
import { connect, useDispatch } from 'react-redux';
import {
    setApiQueryState,
    setApiQueryStateAndRouteAndFetch,
} from '../actions/apiQueryActions';
import { fetchDataIfNeeded } from '../actions/dataActions.js';

import FocusableHeader from '../focusableHeader.jsx';
 

/* Worth noting nothing propogates without domToReact being run ... take heed and beware of hooks */

const icons = {

    'PhD-thesis': (
        <Icon path={mdiBookOpenPageVariantOutline} size={1} color="hsl(32, 70%, 30%)" /> 
    ),

    'book': (
        <Icon path={mdiBookOpenVariant} size={1} color="hsl(32, 70%, 30%)" />
    ),
    'book-chapter': (
        <Icon
            path={mdiBookOpenPageVariant}
            size={1}
            color="hsl(64, 70%, 30%)"
        />
    ),
    'journal-article': (
        <Icon path={mdiFile} size={1} color="hsl(64, 70%, 30%)" />
    ),
    'newspaper-article': (
        <Icon
            path={mdiNewspaperVariantOutline}
            size={1}
            color="hsl(128, 70%, 30%)"
        />
    ),
    'review': <Icon path={mdiFileFind} size={1} color="hsl(160, 70%, 30%)" />,
    'pamphlet': (
        <Icon path={mdiNoteOutline} size={1} color="hsl(192, 70%, 30%)" />
    ),
    'interview-with': (
        <Icon
            path={mdiAccountMultipleOutline}
            size={1}
            color="hsl(224, 70%, 30%)"
        />
    ),
    'lecture': <Icon path={mdiTeach} size={1} color="hsl(256, 70%, 30%)" />,
    'feature': (
        <Icon path={mdiTelevisionClassic} size={1} color="hsl(288, 70%, 30%)" />
    ),
    'talks': <Icon path={mdiMicrophone} size={1} color="hsl(320, 70%, 30%)" />,
    'archive': (
        <Icon path={mdiPackageVariant} size={1} color="hsl(352, 70%, 30%)" />
    ),

    'person': <Icon path={mdiAccount} size={1} color="#bf360c" />,
    'category': <Icon path={mdiShape} size={1} color="#bf360c" />,

    'external-link': <Icon path={mdiOpenInNew} size={1} color="#bf360c" />,
};

const useStyles = makeStyles((theme) => ({
    monogr_of_article: {
        marginTop: theme.spacing(1),
    },
    named_container_paper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    paper_label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.875rem',
        fontWeight: 500,
        paddingBottom: theme.spacing(2),
    },

    item_type_label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'fontSize': '0.875rem',
        'fontWeight': 500,
        'paddingBottom': theme.spacing(2),
        'marginBottom': theme.spacing(3),
        '& svg': {
            position: 'relative',
            top: '6px',
            marginRight: '8px',
        },
    },

    link: {
        'color': theme.palette.secondary.main,
        'textDecoration': 'none',
        padding: '0px',
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },

    titlevalue: {
        '& ul': {
            'marginTop': 0,
            'list-style': 'inside',
            '& li': {
                listStyle: 'none',
                marginLeft: theme.spacing(10.1),
            },
        },
    },

    title_value_title: {
        minWidth: '120px',
        maxWidth: '120px',
        display: 'inline-block',
        marginRight: '10px',
        wordBreak: 'break-word',
        outline: 'none',
    },

    paragraph: {
        marginBottom: theme.spacing(2),
    },
}));

function ListItemLink(props) {
    console.log("LinkItemLink-->", props);
    return <ListItem button {...props} />;
}

const Item = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    console.log('props-->', props);

    return (
        <>
            <Typography className={classes.item_type_label}>
                {icons[props.type]} {_(props.type)}{' '}
                {props.subtype ? '(' + _(props.subtype) + ')' : ''}
            </Typography>
            {domToReact(props.children, options)}
        </>
    );
};

const DateLine = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    console.log("DateLine-->", props);
    return (
        <Typography variant="h6" component="p">
            {props.when}
        </Typography>
    );
};

const ItemTitle = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    console.log("ItemTitle-->", props);
    let title;
    switch (props.type) {
        case 'journal-article':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;
        case 'newspaper-article':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;
        case 'book-chapter':
            title = <>&lsquo;{domToReact(props.children, options)}&rsquo;</>;
            break;

        case 'book':
            title = <i>{domToReact(props.children, options)}</i>;
            break;
        default:
            title = domToReact(props.children, options);
            break;
    }
    return (
        <Typography variant="h4" component="div">
            <FocusableHeader autoFocus>{title}</FocusableHeader>
        </Typography>
    );

};

/* issue caused with unamed authors in this component */
const ItemAuthors = (props) => {
    const classes = useStyles();
    const authors = props.children.filter(
        (item) => item.name === 'react:author',
    );
    console.log("ItemAuthors-->", authors)
    if (authors.length !== 0) {
    return (
        <Typography variant="h5" component="h2">
            {authors
                .map((item, i) => {
                    console.log('author item--->', item.attribs['data-href']);
                    console.log("item count: " + i);
                    /* not hobsbawm */
                    return item.attribs['data-href'] !== '/person/' && item.attribs['data-href'] !== '/person/hobsbawm' ? (
                        <RouterLink
                            to={item.attribs['data-href']}
                            className={classes.link}
                        >
                            {domToReact(item.children, options)}                            
                        </RouterLink>

                    ) : (
                        /* hobsbawm */
                        <><span className="hobsbawm">{domToReact(item.children, options)}</span></>
                    );
                })
                /* og code */
                .reduce((prev, curr) => [prev, ', ', curr])}
{
                /* 
                    no initial value set in oc so caused a Type Error when reduce was called.
                    Fixed with a second arg containing empty array (initialisation).
                    Now causing issue with extraneous ',' appearing on single author entries.
                    ESM
                */
                /*
                    .reduce((prev, curr) => 
                        {
                            return [prev, function() { if (prev != null || undefined) return ", "; else return " "; }, curr];
                        })
                */
                }
            {props.type === 'editors'
                ? ' (ed' + (authors.length > 1 ? 's' : '') + '.)'
                : ''}
        </Typography>
    );
    /* patch put in to get around missing authors in the props */
    } else {
        console.log("NO AUTHORS ...");
        return (
        <Typography component="div" paragraph={false}>
            no given author(s)
            {domToReact(props.children, options)}
        </Typography>
        );
    }
};


const NamedContainer = (props) => {
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    console.log("NamedContainer-->", props);
    return (
        <Box mt={1} wrap="nowrap">
            <Paper elevation={3} className={classes.named_container_paper}>
                <Typography className={classes.paper_label} style={{overflowWrap: "break-word"}}>
                    {_('named-container-type-' + props.type)}
                </Typography>
                {domToReact(props.children, options)}
            </Paper>
        </Box>
    );
};

const MonogrTitle = (props) => {
    console.log("MonogrTitle-->", props);
    return (
        <Box mb={3}>
            <Typography variant="h5" component="h1">
                {domToReact(props.children, options)}
            </Typography>
        </Box>
    );
};

const PageGrid = (props) => {
    const classes = useStyles();
    console.log("PageGrid-->", props);
    return (
        <Grid container wrap='nowrap' spacing={5} className={classes.monogr_of_article}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridLeft = (props) => {
    const classes = useStyles();
    console.log("GridLeft-->", props)
    return (
        <Grid item xs={8}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridRight = (props) => {
    console.log('GridRight-->', props);
    return (
        <Grid item xs={4}>
            {domToReact(props.children, options)}
        </Grid>
    );
};

const GridRightList = (props) => {
    console.log('GridRightList-->', props);
    const { _ } = useContext(LanguageContext);
    return (
        <List
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {_('side-container-header-' + props.header)}
                </ListSubheader>
            }
        >
            {domToReact(props.children, options)}
        </List>
    );
};

const GridRightListItem = (props) => {
    console.log('GridRightListItem-->', props);
    const { _ } = useContext(LanguageContext);
    const isLocalLink = props.link && props.link.startsWith('/');

    return isLocalLink ? (
        <ListItemLink to={props.link} component={RouterLink}>
            <ListItemText
                primary={
                    props.translation
                        ? _(props.translation + '-' + props.value)
                        : props.value
                }
            />{' '}
        </ListItemLink>
    ) : props.link ? (
        <ListItemLink href={props.link} component="a" target="_blank">
            <ListItemText
                primary={
                    props.translation
                        ? _(props.translation + '-' + props.value)
                        : _(props.value)
                }
            />{' '}
            {icons['external-link']}
        </ListItemLink>
    ) : (
        <ListItemText
            primary={
                props.translation
                    ? _(props.translation + '-' + props.value)
                    : _(props.value)
            }
        />
    );
};

const TitleValue = (props) => {
    console.log("TitleValue-->", props);
    const classes = useStyles();
    const { _ } = useContext(LanguageContext);
    const containsList = props.children.some((c) => c.name === 'ul');
    
    if (props.translation || props.translatevalue) {
        return (
            <Grid container wrap="nowrap">
                <Grid item>
                    <b className={classes.title_value_title}>
                        {_('tv-title-' + props.title)}
                    </b>
                </Grid>
                <Grid item id={useId}>{_(props.translatekey + props.translatevalue)}</Grid>
            </Grid>
        );
    } else {
        const containsList = props.children.some((c) => c.name === 'ul');
        console.info(containsList)
        return (
            <Grid container wrap="nowrap">
                <Grid item>
                    <b className={classes.title_value_title}>
                        {_('tv-title-' + props.title)}
                    </b>
                </Grid>

                {containsList ? (
                    /* <Grid item xs={10}/> removed as it was breaking wrapping on republished chapters.
                    <>
                    */
                    <Grid item>{domToReact(props.children, options)}</Grid>
                    /*
                    </>
                    */
                ) : (
                    <Grid item>{domToReact(props.children, options)}</Grid>

                )}
            </Grid>
        );
    }
};

const BiblScope = (props) => {
    console.log("BiblScope-->", props);
    return <Box mt={3}>{domToReact(props.children, options)}</Box>;
};

const Paragraph = (props) => {
    const classes = useStyles();
    console.log("Paragraph-->", props);
    return (
        <Typography variant="body2" className={classes.paragraph} component="div">
            {domToReact(props.children, options)}
        </Typography>
    );
};

const Link = (props) => {
    const classes = useStyles();
    console.log("Link-->", props);
    return (
        <RouterLink to={props.href} className={classes.link}>
            {domToReact(props.children, options)}
        </RouterLink>
    );
};

const lookups = {
    'grid': PageGrid,
    'grid-left': GridLeft,
    'grid-right': GridRight,
    'grid-right-list': GridRightList,
    'grid-right-list-item': GridRightListItem,
    'item': Item,
    'item-date': DateLine,
    'item-title': ItemTitle,
    'item-authors': ItemAuthors,
    //'monogr': Monogr,
    'monogr-title': MonogrTitle,
    'key-value': TitleValue,
    'bibl-scope': BiblScope,
    'named-container': NamedContainer,
    'p': Paragraph,
    'link': Link,
};

const options = {
    replace: (domNode) => {
        if (domNode.name && domNode.name.startsWith('react:')) {
            const attribs = Object.fromEntries(
                Object.entries(domNode.attribs).map(([k, v]) => {
                    return [k.replace('data-', ''), v];
                }),
            );
            console.table(attribs)
            const elem_name = domNode.name.split(':')[1];
            return React.createElement(
                lookups[elem_name],
                attribs,
                domNode.children,
            );
        }
    },
};


const html = `<react:item data-type="journal-article">
    <react:item-date data-when="1969">1969</react:item-date>
    <react:item-title data-type="journal-article">Problems of a Communist History</react:item-title>
    
    <react:item-authors data-href="/author/hobsbawm">
        <react:author data-href="/author/hobswbawm">Eric Hobsbawm</react:author>
        <react:author data-href="/author/another">A. N. Other</react:author>
    </react:item-authors>
    <react:grid>
        <react:grid-left>
            <react:named-container data-type="journal-article">
                <react:monogr-title>New Left Review</react:monogr-title>
                    <react:key-value data-title="language">EN</react:key-value>
                    <react:key-value data-title="published">London</react:key-value> 
                    <react:key-value data-title="publisher">Weidenfeld &amp; Nicolson</react:key-value>
                    <react:key-value data-title="date">1969</react:key-value>
                    

                    <react:bibl-scope>
                        <react:key-value data-title="volume">54</react:key-value>
                        <react:key-value data-title="pages">11–24</react:key-value>
                    </react:bibl-scope>
            </react:named-container>

            <react:named-container data-type="notes">
                <react:p>Reprinted in <react:link data-href="/item/revolutionaries">Revolutionaries: Contemporary Essays</react:link> Chapter 1.</react:p>
            </react:named-container>

            <react:named-container data-type="translations">
                <react:key-value data-title="spanish"><react:link data-href="/item/problems-historia-comunista">Problemas de una historia comunista</react:link></react:key-value>
                <react:key-value data-title="french"><react:link data-href="/item/problemes-histoire-communiste">Problèmes d'une histoire communiste</react:link></react:key-value>
            </react:named-container>
        </react:grid-left>

        <react:grid-right>
            <react:grid-right-list data-header="categories">
                <react:grid-right-list-item data-translation="category" data-value="communism" link="/category/communism">ja</react:grid-right-list-item>
                <react:grid-right-list-item data-translation="category" data-value="other" link="/category/other">je</react:grid-right-list-item>
              
            </react:grid-right-list>

            <react:grid-right-list data-header="links">
                <react:grid-right-list-item data-value="WorldCat Entry" link="http://www.worldcat.org/oclc/137349775"/>
            </react-grid-right-list>
        </react:grid-right>
    
    
    </react:grid>
       

</react:item>`;

const Bibl = (props) => {
    console.log("Bibl-->", props.apiQueryState);
    const dispatch = useDispatch();
    const { id } = useParams();
    const itemData =
        props.allItemData[
            hash({
                type: props.variant ? props.variant : 'bibl',
                identifier: id,
            })
        ] ?? null;
    console.table(props);

    useEffect(() => {
        dispatch(
            fetchDataIfNeeded({
                type: props.variant ? props.variant : 'bibl',
                identifier: id,
            }),
        );
    }, [itemData]);

    return (
        <>
            <Helmet>
                <title>Eric Hobsbawm Bibliography | Item</title>
            </Helmet>
            {itemData && itemData.isFetching ? (
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={9}>
                        <Box mt={5}>
                            <LinearProgress />
                        </Box>
                    </Grid>
                </Grid>
            ) : itemData && itemData.data ? (
                <Box mt={4}>
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={10}>
                            {parse(itemData.data, options)}
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
    query: state.router.location.query,
    allItemData: state.itemData,
    apiQueryState: state.apiQueryState,
});

export default connect(mapStateToProps)(Bibl);
