import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LanguageContext from '../utils/LanguageContext';

import FocusableHeader from '../focusableHeader.jsx';

const useStyles = makeStyles((theme) => ({
    paddedPaper: {
        padding: theme.spacing(3),
    },

    search: {
        position: 'relative',
        //border: 'thin solid gray',

        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {},
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),

        width: '100%',

        fontSize: '1.5rem',
    },
    resultsPaper: {
        padding: theme.spacing(5, 5, 4),
    },
    resultItemGroupPaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    resultItemPaper: {
        cursor: 'pointer',
        padding: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    link: {
        'color': theme.palette.secondary.main,
        'textDecoration': 'none',
        '&:hover': {
            color: theme.palette.secondary.dark,
        },
    },

    searchResultLabel: {
        //paddingBottom: theme.spacing(6),
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(1),
    },
    item_type_label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'fontSize': '0.875rem',
        'fontWeight': 500,
        'paddingBottom': theme.spacing(2),
        'marginBottom': theme.spacing(3),
        '& svg': {
            position: 'relative',
            top: '6px',
            marginRight: '8px',
        },
    },
}));
const Link = (props) => {
    const classes = useStyles();
    const isLocal = props.href.startsWith('/');
    if (isLocal) {
        return (
            <RouterLink to={props.href} className={classes.link}>
                {domToReact(props.children, options)}
            </RouterLink>
        );
    } else {
        return (
            <a href={props.href} className={classes.link} target="_blank">
                {domToReact(props.children, options)}
            </a>
        );
    }
};

const AlignRight = (props) => {
    return (
        <span style={{ textAlign: 'right' }}>
            {domToReact(props.children, options)}
        </span>
    );
};

const lookups = {
    'link': Link,
    'align-right': AlignRight,
};

const options = {
    replace: (domNode) => {
        if (domNode.name && domNode.name.startsWith('react:')) {
            const attribs = Object.fromEntries(
                Object.entries(domNode.attribs).map(([k, v]) => {
                    return [k.replace('data-', ''), v];
                }),
            );
            const elem_name = domNode.name.split(':')[1];
            return React.createElement(
                lookups[elem_name],
                attribs,
                domNode.children,
            );
        }
    },
};

const Biography = () => {
    const { _ } = useContext(LanguageContext);
    return (
        <>
            <Helmet>
                <title>Eric Hobsbawm Bibliography | Biography</title>
            </Helmet>

            <Grid container direction="row" justifyContent="center">
                <Grid item xs={7}>
                    <Box mt={2}>
                        <Typography variant="h4" component="div">
                            <FocusableHeader autoFocus className="leader">{_('biography_h1')}</FocusableHeader>
                        </Typography>
                        <Box mt={2}>
                            <Typography variant="body1" paragraph={false} component="div"> 
                                {parse(_('biography_text'), options)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Biography;
