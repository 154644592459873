import hash from 'hash-it';

import { REQUEST_DATA, RECEIVE_DATA } from '../actions/dataActions';

const itemData = (state = { isFetching: false, data: '' }, action) => {
    switch (action.type) {
        case REQUEST_DATA:
            return { ...state, isFetching: true };
        case RECEIVE_DATA:
            return {
                ...state,
                isFetching: false,
                data: action.data,
                receivedAt: action.receivedAt,
            };
        default:
            return state;
    }
};

const itemDataByRequest = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_DATA:
        case RECEIVE_DATA:
            //console.log('iteDataByRequest.action', action);
            return {
                ...state,
                [hash(action.apiQueryState)]: itemData(
                    state[action.apiQueryState],
                    action,
                ),
            };
        default:
            return state;
    }
};

export default itemDataByRequest;
