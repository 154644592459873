import React, { Component } from 'react';

class BasicHeader extends Component {
    headingRef = React.createRef()
  
    componentDidMount() {
      this.headingRef.current.focus()
    }
  
    render() {
      return (
          <h4
            ref={this.headingRef}
            className="focusableHeader">
               {this.props.children}
          </h4>
      )
    }
};

export default BasicHeader;
