export const IS_DEV = false;

// MAKE SURE TO OMIT TRAILING SLASH ON /api !!

// Need to specify server when running yarn dev environment
// on different server to the exist instance
const DEV_SERVER = 'http://localhost:8080';
const DEV_API_EXTENSION = '/exist/apps/hobsbawm-app/api';

// Leave blank to just look at served app route.
const PROD_SERVER = '';
const PROD_API_EXTENSION = '/exist/apps/hobsbawm-app/api';

export const API_URL = IS_DEV
    ? `${DEV_SERVER}${DEV_API_EXTENSION}`
    : `${PROD_SERVER}${PROD_API_EXTENSION}`;
