import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import UniLogo from '../assets/UoE_Stacked Logo_White_v1_160215.png';
import UKRILogo from '../assets/ukri2.png';
import EditorWrapText from 'material-ui/svg-icons/editor/wrap-text';
import wrapText from 'material-ui/svg-icons/editor/wrap-text';

const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.primary.dark,
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    appdetails: {
        fontSize: 10,
        padding: 4,
    },
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.appBar} variant="elevation">
            <Toolbar>
                <img
                    style={{
                        height: '6rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                    }}
                    src={UniLogo}
                    alt="University of Edinburgh logo"
                />
                <img
                    style={{
                        marginLeft: '2rem',
                        height: '6rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                    }}
                    src={UKRILogo}
                    alt="UKRI Logo"
                />
                <div className={classes.grow} />
                <Typography>© University of Edinburgh 2020</Typography>
            </Toolbar>
            <Toolbar>
                <div className={classes.grow} />
                <div className={classes.appdetails}>
                    <Typography className={classes.appdetails}>Version 0.91</Typography>
                </div>
                <div className={classes.appdetails}>
                    <Typography className={classes.appdetails}>Date stamp 03/02/22</Typography>
                </div>
            </Toolbar>
            </AppBar>
    );
};

export default Footer;
