import { push, replace } from 'connected-react-router';
import * as qs from 'qs';

import { fetchDataIfNeeded } from './dataActions';

export const SET_API_QUERY_STATE = 'SET_API_QUERY_STATE';
export const setApiQueryState = apiQueryState => {
    //console.log('setApiQueryState', apiQueryState);
    return {
        type: SET_API_QUERY_STATE,
        apiQueryState: apiQueryState,
    };
};

// A thunked action to dispatch searchForm and router
// settings in one
export const setApiQueryStateAndRoute = apiQueryState => {
    return dispatch => {
        // Set the state.searchForm

        // If there is a search value in searchFormObject,
        // set the router to /search?search=whatever
        // otherwise, just /

        // Going to have to modify this at some point to check whether *any*
        // of the searchFormObject keys has a value...
        console.log('setApiQueryStateAndRoute apiQueryState', apiQueryState);
        if (apiQueryState.type === 'search') {
            dispatch(
                replace(
                    `/search${qs.stringify(apiQueryState.query, {
                        arrayFormat: 'brackets',
                        encode: true,
                        addQueryPrefix: true,
                    })}`,
                ),
            );
        } else if (
            apiQueryState.type &&
            apiQueryState.type !== 'search' &&
            apiQueryState.identifier
        ) {
            dispatch(
                replace(`/${apiQueryState.type}/${apiQueryState.identifier}/`),
            );
        } else {
            // If no search, don't even set router to /
            //alert('setQueryStateAndRoute setting to /');
            dispatch(replace(`/`));
        }

        dispatch(setApiQueryState(apiQueryState));
    };
};

export const setApiQueryStateAndRouteAndFetch = apiQueryState => {
    return dispatch => {
        dispatch(fetchDataIfNeeded(apiQueryState));
        dispatch(setApiQueryStateAndRoute(apiQueryState));
    };
};
